import { get } from 'helpers/apiHelpers';

export const createHelpScout = async (location, user, brand) => {
  const res = await get(user.id);
  const roles = res.permissions.map(role => role.name).join(', ');

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'helpScoutScript';

  const code = `
  !function(e,t,n){
    function a(){
      var e=t.getElementsByTagName("script")[0],
          n=t.createElement("script");
      n.type="text/javascript",
      n.async=!0,
      n.src="https://beacon-v2.helpscout.net",
      e.parentNode.insertBefore(n,e)
    }
    if(e.Beacon=n=function(t,n,a){
      e.Beacon.readyQueue.push({method:t,options:n,data:a})
    },n.readyQueue=[],"complete"===t.readyState)return a();
    e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)
  }(window,document,window.Beacon||function(){});
  window.Beacon('init', '3616da35-ccb0-403a-88cf-ff5c7b78879f');
  window.Beacon('config', {enableFabAnimation: false, enableSounds: false});
  window.Beacon('identify', {
    name:'${user.firstName} ${user.lastName}',
    email:'${user.email}',
    jobTitle:'${roles}',
    company:'${brand.name}',
    telefon:'${user?.phone}',
    'adres-admina':'${location}'
  })
`;

  script.appendChild(document.createTextNode(code));
  document.body.appendChild(script);
};

export const removeHelpScout = () => {
  const script = document.getElementById('helpScoutScript');
  if (script) {
    script.parentNode.removeChild(script);
    window.Beacon('destroy');
  }
};
