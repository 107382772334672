import React, { Component } from 'react';
import { combineStyles } from 'helpers/helpers';
import { post, put } from 'helpers/apiHelpers';
import { withToast } from 'material-ui-toast-redux';
import { connect } from 'react-redux';
import { fetchZones } from 'actions/Zones';
import { fetchDriver } from 'actions/Drivers';

//styles
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

//material-ui core
import FormLabel from '@material-ui/core/FormLabel';

// components
import AdminTable from 'layouts/AdminTable';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import SelectInput from 'components/FormSelect/SelectInput';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class Drivers extends Component {
  state = {
    name: '',
    excludeFromGoodspeedIntegration: false,
    selectedZones: [],
  };

  editId = this.props.match.params.id;
  isEdit = this.props.location.pathname.includes('edit');

  componentDidMount() {
    this.props.fetchZones();
    if (this.isEdit) {
      this.props.fetchDriver(this.editId).then(response =>
        this.setState({
          name: response.name,
          excludeFromGoodspeedIntegration:
            response.excludeFromGoodspeedIntegration,
          selectedZones: response.zones,
        })
      );
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validateForm = () => {
    return this.state.name;
  };

  handleToggle = key => {
    this.setState(prev => ({ ...prev, [key]: !this.state[key] }));
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      return this.props.openToast({
        messages: [this.props.t('drivers.fillAllFields')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const data = {
      name: this.state.name,
      zones: this.state.selectedZones,
      excludeFromGoodspeedIntegration:
        this.state.excludeFromGoodspeedIntegration,
    };

    const action = this.isEdit
      ? put(`/drivers/${this.editId}`, data)
      : post('/drivers', data);

    action.then(() => this.props.history.push('/admin/drivers'));
  };

  render() {
    const { classes, t } = this.props;

    return (
      <AdminTable
        title={
          this.isEdit
            ? this.props.t('drivers.edit')
            : this.props.t('drivers.add')
        }
      >
        <GridContainer>
          <GridItem sm={12}>
            <h5>{this.props.t('drivers.basicInfo')}</h5>
            <GridContainer>
              <GridItem sm={6} data-cy="driver-code">
                <FormLabel className={classes.labelHorizontal}>
                  {this.props.t('drivers.driverCode')} *
                </FormLabel>
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: 'name',
                    value: this.state.name,
                    onChange: ev => this.handleChange(ev),
                  }}
                />
              </GridItem>
              <GridItem sm={6} data-cy="driver-excludeFromGoodspeedIntegration">
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() =>
                        this.handleToggle('excludeFromGoodspeedIntegration')
                      }
                      checked={this.state.excludeFromGoodspeedIntegration}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                  label={
                    <>
                      {t('drivers.excludeFromGoodspeedIntegration.title')}
                      <div style={{ marginLeft: '10px' }}>
                        <Tooltip
                          title={
                            <div>
                              <h4>
                                {t(
                                  'drivers.excludeFromGoodspeedIntegration.title'
                                )}
                              </h4>
                              <h4>
                                {t(
                                  'drivers.excludeFromGoodspeedIntegration.description'
                                )}
                              </h4>
                            </div>
                          }
                          placement="right"
                        >
                          <Info fontSize={'small'} />
                        </Tooltip>
                      </div>
                    </>
                  }
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem sm={6}>
                <SelectInput
                  classes={classes}
                  multiple={true}
                  label={this.props.t('drivers.assignedZones')}
                  mapBy="name"
                  trackBy="@id"
                  name="selectedZones"
                  value={this.state.selectedZones}
                  options={this.props.zones}
                  handleChange={this.handleChange}
                  id="roles"
                  size={12}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <FormControlButtons
          classes={classes}
          discardText={this.props.t('common.shared.cancel')}
          submitText={this.props.t('common.shared.save')}
          cancelPath="/admin/drivers"
          handleSubmit={this.handleSubmit}
        />
      </AdminTable>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  zones: state.Zones.zones,
  driver: state.Drivers.driver,
});

const mapDispatchToProps = dispatch => ({
  fetchZones: () => dispatch(fetchZones()),
  fetchDriver: id => dispatch(fetchDriver(id)),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(),
  withToast,
  withStyles(combinedStyles)
);

export default enhance(Drivers);
